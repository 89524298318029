<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M39.81 26.075a6.452 6.452 0 01-6.3 6.551h-4.505a.545.545 0 010-1.089h4.488a5.36 5.36 0 005.225-5.448v-.01a5.439 5.439 0 00-2.94-4.852l-.32-.167.029-.358c0-.049.01-.1.015-.144.007-.049.012-.098.013-.147a4.38 4.38 0 00-4.26-4.457c-.482.008-.96.105-1.407.284l-.43.172-.237-.4a6.981 6.981 0 00-5.918-3.395h-.015a7.177 7.177 0 00-6.9 5.63l-.058.253-.233.115a6.814 6.814 0 00-3.78 6.124v.015a6.655 6.655 0 006.506 6.781h3.168a.544.544 0 110 1.089h-3.19a7.747 7.747 0 01-7.577-7.884 7.912 7.912 0 014.156-6.983 8.26 8.26 0 017.907-6.229h.014a8.054 8.054 0 016.613 3.547c.446-.134.909-.205 1.375-.212h.02a5.469 5.469 0 015.336 5.556v.02a6.518 6.518 0 013.206 5.638z" fill="#000"/><path d="M25.52 18.611a1.636 1.636 0 011.16.48l6.187 6.186a1.709 1.709 0 01-2.418 2.417l-2.993-2.993v13.3a1.937 1.937 0 01-3.872 0v-13.3l-2.993 2.993a1.71 1.71 0 11-2.418-2.417l6.186-6.186a1.636 1.636 0 011.162-.48zm6.139 8.656a.78.78 0 00.552-1.333l-6.186-6.186a.714.714 0 00-1.008 0l-6.186 6.186a.781.781 0 001.1 1.1l3.785-3.785a.464.464 0 01.792.328v14.424a1.008 1.008 0 102.016 0v-14.42a.464.464 0 01.792-.328l3.785 3.785a.778.778 0 00.558.229z" fill="#000"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconActionImport'
  };
</script>
